import {
  SimpleGrid,
  Flex,
  Heading,
  Text,
  Button,
  Image,
} from "@chakra-ui/react";
import { WebeetMain } from "../../assets/layout";

const Hero = ({ endpoint }) => {
  const studio = {
    studio: "webeet",
    heading: "EXPERT DIGITAL SERVICES, STARTUP FRIENDLY PRICING",
    text: "We empower startups with our multifaceted expertise. From superior web development to insightful data analytics, cutting-edge AI integration to robust cybersecurity, we offer a spectrum of services designed to propel your business to new heights. Your startup's success story begins here.",
    illustration: WebeetMain,
    primaryColor: "#B9B0E8",
    secondaryColor: "#CEC8EF",
  };
  const {
    heading,
    text,
    illustration,
    primaryColor,
    secondaryColor,
    textColor,
  } = studio;

  return (
    <SimpleGrid
      mx={[2, 4, 4, 8, "auto"]}
      my={12}
      as='section'
      columns={[1, 1, 1, 1, 2]}
      justifyItems='end'
      maxW={["100%", "1200px"]}
      gap={[8, 4]}
    >
      <Flex
        flexDir='column'
        justifyContent={endpoint === "student" ? "center" : "start"}
        alignItems='start'
        gap={4}
      >
        <Heading textTransform='uppercase' color={textColor} fontWeight='black'>
          {heading}
        </Heading>
        <Text fontSize={endpoint === "student" ? "2xl" : "lg"} lineHeight={8}>
          {text}
        </Text>
        <a href='#contact'>
          {endpoint !== "student" && (
            <Button
              bgColor='#B9B0E8'
              color='white'
              onClick={(e) => {
                e.preventDefault();
                const element = document.getElementById("contact");
                if (element) {
                  const yOffset = -80;
                  const yPosition =
                    element.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;
                  window.scrollTo({ top: yPosition, behavior: "smooth" });
                }
              }}
            >
              START YOUR PROJECT
            </Button>
          )}
        </a>
      </Flex>
      <Flex w='100%' justifyContent='center' alignItems='center'>
        <Image src={illustration} alt='illustration' />
      </Flex>
    </SimpleGrid>
  );
};

export default Hero;
