import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { not_found } from "../assets/404";

const NotFound = () => {
  return (
    <Flex height='100vh' justifyContent={"center"} maxW='100%'>
      <Flex flexDir='column' align='center' maxWidth='100%' width='800px'>
        <Navbar noContact />
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          mt={10}
          px={[5, 5, 10, 10, 10]}
          maxW='100%'
        >
          <Box width='100%' textAlign='left'>
            <Link to='/'>{"< Home"}</Link>
          </Box>
          <Heading mb={5} mt={10}>
            Page not found
          </Heading>
          <Text maxW='100%' width={"500px"} textAlign={"center"}>
            We are Sorry for that, but it seems like something went wrong...
            Please go back to the home page.
          </Text>
          <Image src={not_found} w={500} maxW='100%' />
        </Flex>
        <Footer noPadding />
      </Flex>
    </Flex>
  );
};

export default NotFound;
