import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import JobCard from "./Card";
import InputSection from "./InputSection";
import { applications, cohorts, cohortText } from "../../data/applications";
import { masterschool_logo } from "../../assets/companies";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Masterschool = () => {
  const { id } = useParams();
  const [domain, setDomain] = React.useState("");
  const [filteredApplications, setFilteredApplications] = React.useState(
    applications[cohorts[id]]
  );

  React.useEffect(() => {
    setFilteredApplications(
      applications[cohorts[id]].filter(
        (app) =>
          domain === "" || app.domain.toLowerCase() === domain.toLowerCase()
      )
    );
  }, [domain, id]);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <Helmet>
        <title>
          Masterschool {capitalizeFirstLetter(cohorts[id])} 2024 | webeet.io
        </title>
      </Helmet>
      <Flex minHeight={"100vh"} flexDirection={"column"} minWidth={"100%"}>
        <Navbar noContact />
        <Flex flexDir='column' mt={10}>
          <Flex flexDir='column' alignItems={"center"}>
            <Box width='100%' alignText='left' maxWidth='800px' mb='20px'>
              <Link to='/'>{"< Back "}</Link>
            </Box>
            <Image
              mb={5}
              boxSize={"60px"}
              objectFit='contain'
              src={masterschool_logo}
              alt={"masterschool"}
            />
            <Heading size='xl' align='center'>
              Welcome {cohortText[cohorts[id]]}
            </Heading>
            <Heading size='lg' align='center'>
              Join Our Network
            </Heading>
            <InputSection setDomain={setDomain} />
            <Flex
              width='100%'
              maxWidth='1200px'
              alignItems='center'
              flexDir='column'
              mb={100}
            >
              {filteredApplications.length > 0 ? (
                filteredApplications.map((app) => (
                  <JobCard cohort_id={id} app={app} />
                ))
              ) : (
                <Text mt={10}>No application was found.</Text>
              )}
            </Flex>
          </Flex>
          <Footer />
        </Flex>
      </Flex>
    </>
  );
};

export default Masterschool;
