import React from "react";

import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
  Button,
  Spinner,
  Box,
} from "@chakra-ui/react";

export const Form = ({
  handleSubmit,
  handleChange,
  form,
  loading,
  endpoint,
}) => {
  const astrid = <span style={{ color: "red" }}>*</span>;

  return (
    <Box as='form' onSubmit={handleSubmit}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <FormControl id='name' mb={4}>
            <FormLabel>Name{astrid}</FormLabel>
            <Input
              bg='white'
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl id='email' mb={4}>
            <FormLabel>Email{astrid}</FormLabel>
            <Input
              bg='white'
              type='email'
              name='email'
              value={form.email}
              onChange={handleChange}
              required
            />
          </FormControl>
          <FormControl id='message' mb={4}>
            <FormLabel>Tell us more{astrid}</FormLabel>
            <Textarea
              bg='white'
              name='message'
              value={form.message}
              onChange={handleChange}
              required
            />
          </FormControl>
          <Button type='submit' bg='#B9B0E8' color='white' mb={4} w='full'>
            Submit
          </Button>
        </>
      )}
    </Box>
  );
};
