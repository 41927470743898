import { Box, Heading, Text } from "@chakra-ui/react";
import newWave from "../../assets/layout/new-waves.svg";

const How = () => {
  return (
    <Box
      h={["850px", "600px"]}
      bgImage={newWave}
      backgroundSize='cover'
      backgroundPosition='center'
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box as='section' id='how' maxW={"1200px"} mx={[10, 10, 10, 10, "auto"]}>
        <Heading my={4}>Premium Quality, Low Prices</Heading>
        <Text my={4} fontSize='lg'>
          We've combined the expertise of industry-leading senior developers
          with the vigor and talent of top-notch junior developers. <br /> Our
          seniors will be your primary point of contact, planning your project,
          architecting your solution, and ensuring everything stays on track.
          Meanwhile, our juniors work diligently behind the scenes to bring your
          vision to life.
        </Text>
        <Text fontSize='lg'>
          This model not only keeps costs down but also ensures a high-quality
          product, enabling your startup to focus on what you do best: serving
          your customers. Welcome to the perfect balance of exceptional quality
          and affordability.
        </Text>
      </Box>
    </Box>
  );
};

export default How;
