import { CheckIcon, SettingsIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { anyway, mindguard, noLedgeloss } from "../assets/companies";
import { FaMoneyBill1 } from "react-icons/fa6";

const links = {
  anyway: {
    se: "https://form.typeform.com/to/qA30gMQS",
    da: "https://form.typeform.com/to/zHfKocHl",
  },
  mindguard: {
    cy: "https://form.typeform.com/to/tBa4ENEL",
    se: "https://form.typeform.com/to/UzgqM3aD",
    da: "https://form.typeform.com/to/XZ3yHH8h",
  },
  noLedgeloss: {
    cy: "https://form.typeform.com/to/b7IrZE8v",
    se: "https://form.typeform.com/to/Wyj6mk5L",
    da: "https://form.typeform.com/to/rgr6C2w0",
  },
};

export const cohorts = {
  dmioa794yausikdhakjhs9756rua: "march",
};

export const cohortText = {
  march: "March Cohort",
};

const what_we_offer = {
  title: "What We Offer",
  text: "This collaboration aims to ensure that all graduates secure a full-time job before the end of the program. You will gain practical experience, refine your skills, and be fully prepared for your next career step!",
  icon: FaMoneyBill1,
  bulletPoints: [
    "6-month part-time paid role (4 hours/day).",
    "Close mentorship from a senior software engineer to guide you through hands-on learning.",
    "A collaborative and innovative environment where independence and creativity are highly valued.",
    "Career development support, including personalized learning guidance.",
    "interview preparation with webeet.io team leads, and assistance from Masterschool’s career team.",
  ],
};

const about = {
  mindguard:
    "Guided by the MindGuard AI (MGAI), our platform empowers healthy productivity by providing real-time emotional and behavioral insights. MindGuard’s AI-driven solutions help individuals, teams, and organizations manage stress, improve focus, and achieve more with less stress across different digital environments.",
  noLedgeloss:
    "NoledgeLoss is an AI-powered solution that helps B2B SaaS companies nurture and expand relationships with small and medium-sized clients at scale. With an emphasis on personalized, automated support, NoledgeLoss turns long-tail accounts into engines of growth by retaining knowledge and enhancing client engagement.",
  anyway:
    "An innovative project under the Data For Change organization, dedicated to reducing road accidents and fostering safer road behaviors through data-driven insights. By harnessing data visualization, analytics, and BI dashboards, ANYWAY brings critical road safety information to the forefront, empowering both the public and authorities to make informed, safety-first decisions.",
};

const topics = {
  mindguard: [
    "AI-Focused",
    "Mental Health",
    "Productivity",
    "Stress Detection",
  ],
  noLedgeloss: [
    "Knowledge Retention",
    "System Integration",
    "CRM Optimization",
    "B2B",
  ],
  anyway: ["Data Visualization", "Road Safety", "BI Analysis", "Public Data"],
};

export const applications = {
  march: [
    {
      id: "diosa7958a7isdjkhaskjfhgkajshdkasjh",
      company: "Mind Guard",
      image: mindguard,
      domain: "data",
      job: "Data Analyst",
      applicationLink: links.mindguard.da,
      topics: topics.mindguard,
      content: [
        {
          title: "About Us",
          text: about.mindguard,
        },
        {
          title: "Key Responsibilities",
          icon: CheckIcon,
          bulletPoints: [
            "Analyze datasets from various platforms to derive insights related to user behavior, stress detection, and engagement patterns.",
            "Work closely with cross-functional teams, contributing to the improvement of AI tools and product offerings.",
            "Utilize SQL and Python for data extraction, analysis, and visualization.",
            "Create reports and dashboards using tools like Tableau to present findings to key stakeholders.",
            "Continuously learn and apply statistical methods and machine learning processes as you grow in the role.",
          ],
        },
        {
          title: "Required Skills",
          icon: SettingsIcon,
          bulletPoints: [
            "Basic knowledge of Python for data manipulation and analysis.",
            "Proficiency in SQL for querying and managing databases.",
            "Familiarity with Tableau or similar data visualization tools.",
            "Completion of the Masterschool Data Analytics program",
          ],
        },
        {
          title: "Willingness to Learn",
          icon: InfoOutlineIcon,
          bulletPoints: [
            "statistical methods and machine learning processes.",
            "Data visualization tools (e.g., PowerBI, Looker).",
            "Data processing frameworks (e.g., Pandas, NumPy).",
            "Machine learning models and behavioral analysis.",
          ],
        },
        what_we_offer,
      ],
    },
    {
      id: "45daisuoitlualweiad7poasidkljahs",
      company: "Mind Guard",
      image: mindguard,
      domain: "software",
      link: "https://www.getmindguard.com/",
      job: "Software Engineer",
      applicationLink: links.mindguard.se,
      topics: topics.mindguard,
      content: [
        {
          title: "About Us",
          text: about.mindguard,
        },
        {
          title: "Key Responsibilities",
          icon: CheckIcon,
          bulletPoints: [
            "Develop and maintain software solutions that integrate with ML and AI tools, improving user experiences and performance.",
            "Collaborate closely with cross-functional teams to optimize data-driven features.",
            "Write clean, efficient, and maintainable code on the client and server side.",
            "Work with databases for data management and storage solutions.",
            "Continuously learn and apply best practices in software engineering as you grow in the role.",
          ],
        },
        {
          title: "Required Skills",
          icon: SettingsIcon,
          bulletPoints: [
            "proficiency in at least one programming language (e.g., JavaScript, Python).",
            "Knowledge of Restful APIs, Web Architecture and HTTP methods.",
            "Knowledge of version control systems (e.g., Git) for collaborative development.",
            "Experience with databases like MongoDB or PostgreSQL.",
            "Completion of Masterschool's software engineering program",
          ],
        },
        {
          title: "Willingness to Learn",
          icon: InfoOutlineIcon,
          bulletPoints: [
            "JavaScript and TypeScript, React and React Native.",
            "Backend development with Node.js (Express.js)",
            "AI and ML integration processes",
            "Python, libraries and tools for AI model integration.",
            "Cloud services like AWS for deployment and scaling.",
          ],
        },
        what_we_offer,
      ],
    },
    {
      id: "45daisu074398iuehsda9ykhdsidkljahs",
      company: "Mind Guard",
      image: mindguard,
      domain: "security",
      link: "https://www.getmindguard.com/",
      job: "Security Analyst",
      applicationLink: links.mindguard.cy,
      topics: topics.mindguard,
      content: [
        {
          title: "About Us",
          text: about.mindguard,
        },
        {
          title: "Key Responsibilities",
          icon: CheckIcon,
          bulletPoints: [
            "Monitor security threats and vulnerabilities across platforms, ensuring timely detection and response.",
            "Audit code for security risks, identifying vulnerabilities and ensuring compliance with best practices.",
            "Conduct security-focused quality assurance (QA) testing to verify the effectiveness of protective measures.",
            "Develop incident response plans and cases to ensure minimal downtime and effective recovery processes.",
            "Provide IT support, manage user access, and enforce secure access control policies.",
          ],
        },
        {
          title: "Required Skills",
          icon: SettingsIcon,
          bulletPoints: [
            "Understanding of common cybersecurity threats, including malware, phishing, and network vulnerabilities.",
            "Basic knowledge of code auditing practices and secure coding standards.",
            "Familiarity with security tools for monitoring and threat detection (e.g., SIEM tools).",
            "Willingness to learn and adapt to new security technologies and best practices.",
            "Completion of a cybersecurity program or bootcamp (no formal degree required).",
          ],
        },
        {
          title: "Willingness to Learn",
          icon: InfoOutlineIcon,
          bulletPoints: [
            "Scripting languages (e.g., Python, Bash) for automation of security tasks.",
            "Version control systems (e.g., Git) for tracking code changes and audits.",
            "Cloud security concepts (e.g., AWS) and managing access control in cloud environments.",
            "Incident response frameworks and disaster recovery strategies.",
          ],
        },
        what_we_offer,
      ],
    },
    {
      id: "45daisuoitldao8540a9saad7poasidkljahs",
      company: "NoledgeLoss",
      image: noLedgeloss,
      domain: "software",
      link: "https://www.noledgeloss.com/",
      job: "Software Engineer",
      applicationLink: links.noLedgeloss.se,
      topics: topics.noLedgeloss,
      content: [
        {
          title: "About Us",
          text: about.noLedgeloss,
        },
        {
          title: "Key Responsibilities",
          icon: CheckIcon,
          bulletPoints: [
            "Develop and maintain a robust platform using React and TypeScript, ensuring a seamless user experience.",
            "Collaborate with cross-functional teams to implement new features, including AI-based tools for knowledge preservation and dynamic booklet generation.",
            "Scale and maintain services using Kubernetes, Docker, and DevOps practices, ensuring high availability and performance.",
            "Create integrations with CRM systems and communication tools using Python and Node.js, automating data processing and enhancing knowledge utilization.",
            "Continuously learn and apply best practices in software engineering to ensure the scalability, security, and efficiency of our solutions.",
          ],
        },
        {
          title: "Required Skills",
          icon: SettingsIcon,
          bulletPoints: [
            "proficiency in at least one programming language (e.g., JavaScript, Python).",
            "Knowledge of Restful APIs, Web Architecture and HTTP methods.",
            "Knowledge of version control systems (e.g., Git) for collaborative development.",
            "Experience with databases like MongoDB or PostgreSQL.",
            "Completion of Masterschool's software engineering program",
          ],
        },
        {
          title: "Willingness to Learn",
          icon: InfoOutlineIcon,
          bulletPoints: [
            "Cloud services (e.g., AWS, GCP) for deploying and scaling applications.",
            "AI models and natural language processing (NLP) for extracting insights from communication data.",
            "CRM systems (e.g., Salesforce, HubSpot) and their integration processes.",
          ],
        },
        what_we_offer,
      ],
    },
    {
      id: "67daisuoitlualweiad8psidfhlkahy",
      company: "NoledgeLoss",
      image: noLedgeloss,
      domain: "data",
      link: "https://www.noledgeloss.com/",
      job: "Data Analyst",
      applicationLink: links.noLedgeloss.da,
      topics: topics.noLedgeloss,
      content: [
        {
          title: "About the Role",
          text: about.noLedgeloss,
        },
        {
          title: "Key Responsibilities",
          icon: CheckIcon,
          bulletPoints: [
            "Design and develop data visualizations and dashboards to provide insights into user behavior and platform performance.",
            "Perform data analysis using integrated data from various systems, identifying trends, patterns, and areas for improvement.",
            "Clean, align, and transform raw data to ensure accuracy and consistency across different datasets.",
            "Collaborate with product and engineering teams to define key metrics and KPIs for monitoring platform performance.",
            "Continuously learn and apply best practices in data visualization and business intelligence to improve analysis quality.",
          ],
        },
        {
          title: "Required Skills",
          icon: SettingsIcon,
          bulletPoints: [
            "Basic knowledge of data visualization tools (e.g., Tableau, PowerBI).",
            "Proficiency in SQL for querying and managing databases.",
            "Experience with data cleaning and transformation using Python or R.",
            "Strong analytical skills and attention to detail.",
            "Willingness to learn and adapt to new BI tools and methodologies.",
          ],
        },
        {
          title: "Willingness to Learn",
          icon: InfoOutlineIcon,
          bulletPoints: [
            "CRM data analysis (e.g., Salesforce, HubSpot).",
            "Statistical methods for data analysis and predictive modeling.",
            "Version control systems (e.g., Git) for collaborative analysis.",
            "Cloud-based data warehouses (e.g., AWS Redshift, Google BigQuery).",
          ],
        },
        what_we_offer,
      ],
    },
    {
      id: "89daisuoitlualweiad9polasidklqwe",
      company: "NoledgeLoss",
      image: noLedgeloss,
      domain: "security",
      link: "https://www.noledgeloss.com/",
      job: "Security Analyst",
      applicationLink: links.noLedgeloss.cy,
      topics: topics.noLedgeloss,
      content: [
        {
          title: "About Us",
          text: about.noLedgeloss,
        },
        {
          title: "Key Responsibilities",
          icon: CheckIcon,
          bulletPoints: [
            "Monitor cybersecurity threats, analyze logs, and respond to incidents to ensure the security of the platform.",
            "Audit code and system configurations for security risks, implementing improvements where necessary.",
            "Conduct security-focused quality assurance (QA) testing to validate the integrity of system updates and deployments.",
            "Develop and maintain incident response plans to ensure minimal downtime and swift recovery in case of security breaches.",
            "Manage IT support tasks such as access management and user permissions, ensuring compliance with best security practices.",
          ],
        },
        {
          title: "Required Skills",
          icon: SettingsIcon,
          bulletPoints: [
            "Understanding of common cybersecurity threats, including malware, phishing, and network vulnerabilities.",
            "Basic knowledge of code auditing practices and secure coding standards.",
            "Familiarity with security tools for monitoring and threat detection (e.g., SIEM tools).",
            "Willingness to learn and adapt to new security technologies and best practices.",
            "Completion of a cybersecurity program or bootcamp (no formal degree required).",
          ],
        },
        {
          title: "Willingness to Learn",
          icon: InfoOutlineIcon,
          bulletPoints: [
            "Scripting languages (e.g., Python, Bash) for automation of security tasks.",
            "Version control systems (e.g., Git) for tracking code changes and audits.",
            "Cloud security concepts (e.g., AWS) and managing access control in cloud environments.",
            "Incident response frameworks and disaster recovery strategies.",
          ],
        },
        what_we_offer,
      ],
    },
    {
      id: "91daisuga2soitlualweiad0polasidklxyz",
      company: "ANYWAY",
      image: anyway,
      domain: "data",
      link: "https://www.anyway.co.il/",
      job: "Data Analyst",
      applicationLink: links.anyway.da,
      options: {
        imageWidth: "100px",
      },
      topics: topics.anyway,
      content: [
        {
          title: "About the Role",
          text: about.anyway,
        },
        {
          title: "Key Responsibilities",
          icon: CheckIcon,
          bulletPoints: [
            "Design and build data visualizations and interactive dashboards for both public and internal users, providing insights into traffic accidents and road safety.",
            "Analyze integrated data from various sources, including traffic reports, road incidents, and user-generated data.",
            "Clean, align, and transform datasets to ensure consistency and accuracy across reports.",
            "Collaborate with the development team to integrate insights into the interactive map and infographic tools.",
            "Continuously learn and apply best practices in data analysis to enhance the quality of reports and visualizations.",
          ],
        },
        {
          title: "Required Skills",
          icon: SettingsIcon,
          bulletPoints: [
            "Knowledge of data visualization tools (e.g., Tableau, PowerBI).",
            "Proficiency in SQL for querying and managing databases.",
            "Experience with data cleaning and transformation using Python or R.",
            "Strong analytical skills and attention to detail.",
          ],
        },
        {
          title: "Willingness to Learn",
          icon: InfoOutlineIcon,
          bulletPoints: [
            "Geospatial data analysis (e.g., using PostGIS, QGIS).",
            "Statistical methods for analyzing accident trends and safety data.",
            "Data integration and ETL processes.",
            "Python libraries such as Pandas and Matplotlib for data analysis and visualization.",
          ],
        },
        what_we_offer,
      ],
    },
    {
      id: "92daisuoitlualweiad1polasidklpqr",
      company: "ANYWAY",
      image: anyway,
      domain: "software",
      link: "https://www.anyway.co.il/",
      job: "Software Engineer",
      applicationLink: links.anyway.se,
      options: {
        imageWidth: "100px",
      },
      topics: topics.anyway,
      content: [
        {
          title: "About the Role",
          text: "Join the ANYWAY project, a cutting-edge initiative under Data For Change, aimed at reducing road accidents and promoting safer road behavior through advanced data tools. As a Software Engineer, you will be instrumental in building the platform that serves data, enables advanced filtering, supports rich visualizations, and integrates with various tools for enhanced user interactions. Your work will be central to delivering a seamless and impactful experience for users, including the public and decision-makers.",
        },
        {
          title: "Key Responsibilities",
          icon: CheckIcon,
          bulletPoints: [
            "Develop and maintain a scalable platform that serves data with advanced filtering and query capabilities.",
            "Implement interactive data visualizations that provide insights into road safety and traffic incidents.",
            "Collaborate with cross-functional teams to define, design, and ship new features that enhance user interactions.",
            "Integrate with third-party tools and APIs to enrich the platform's data offerings and functionality.",
            "Optimize platform performance and ensure a seamless user experience across web and mobile interfaces.",
          ],
        },
        {
          title: "Required Skills",
          icon: SettingsIcon,
          bulletPoints: [
            "proficiency in at least one programming language (e.g., JavaScript, Python).",
            "Knowledge of Restful APIs, Web Architecture and HTTP methods.",
            "Knowledge of version control systems (e.g., Git) for collaborative development.",
            "Experience with databases like MongoDB or PostgreSQL.",
            "Completion of Masterschool's software engineering program",
          ],
        },
        {
          title: "Willingness to Learn",
          icon: InfoOutlineIcon,
          bulletPoints: [
            "JavaScript and TypeScript",
            "A framework like React or Vue.",
            "Strong knowledge of backend development using Node.js and experience with RESTful APIs.",
            "Experience with data handling and databases (e.g., PostgreSQL, MongoDB).",
          ],
        },
        what_we_offer,
      ],
    },
  ],
};
