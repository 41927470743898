import {
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Hide,
  Image,
  Show,
  Tag,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const JobCard = ({ app, cohort_id }) => {
  function getRandomColor() {
    // Pastel colors have high RGB values with slight variation
    const r = Math.floor(Math.random() * 128 + 127); // Random value between 127 and 255
    const g = Math.floor(Math.random() * 128 + 127); // Random value between 127 and 255
    const b = Math.floor(Math.random() * 128 + 127); // Random value between 127 and 255

    const color = `rgb(${r}, ${g}, ${b})`;

    return color;
  }
  // Check if a color is dark or light by calculating luminance
  function isDarkColor(r, g, b) {
    // Calculate relative luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return true if the color is dark, otherwise false
    return luminance < 0.5;
  }

  // Get text color based on background color luminance
  function getTextColorForBackground(color) {
    // Extract RGB values from color
    const rgb = color.match(/\d+/g).map(Number);
    const [r, g, b] = rgb;

    return isDarkColor(r, g, b) ? "white" : "#333";
  }

  return (
    <Card p={5} width='800px' maxWidth='100%' mb={5}>
      <Flex>
        <CardBody>
          <Flex
            w='100%'
            justifyContent='space-between'
            alignItems='center'
            mb={3}
          >
            <Heading fontWeight='thin' size='md'>
              <Text as='b'>{app.job} </Text>| {app.company}
            </Heading>
            <Image
              ml={3}
              height={app.isImageWhite ? "40px" : "40px"}
              width={
                app.options?.imageWidth || (app.isImageWhite ? "60px" : "40px")
              }
              objectFit='contain'
              src={app.image}
              alt={app.company}
              p={app.isImageWhite ? [2, 2, 2, 2, 3] : ""}
              bg={app.isImageWhite ? "#333" : ""}
              borderRadius={app.isImageWhite ? "xl" : ""}
            />
          </Flex>
          <Show above='md'>
            <Text mb={5}>{app.content[0].text}</Text>
          </Show>
          <Hide above='md'>
            <Text mb={5} fontSize='sm'>
              {app.content[0].text.slice(0, 100) + "..."}
            </Text>
          </Hide>
          {/* <Flex mb={3} gap={3} wrap={"wrap"}>
            {app.topics?.length > 0 &&
              app.topics.map((topic) => {
                const randomColor = getRandomColor();
                const textColor = getTextColorForBackground(randomColor);
                return (
                  <Tag fontSize='xs' background={randomColor} color={textColor}>
                    {topic.toUpperCase()}
                  </Tag>
                );
              })}
          </Flex> */}
          <Flex flexDirection='row-reverse' mt={[10, 10, 10, 3, 3]}>
            <Link to={`/apply/cohort/${cohort_id}/job/${app.id}`}>
              <Button bg='#B9B0E8' color='white'>
                Read More
              </Button>
            </Link>
          </Flex>
        </CardBody>
      </Flex>
    </Card>
  );
};

export default JobCard;
