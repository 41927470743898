import { useState } from "react";
import { Box, Heading, AlertIcon, Alert, CheckboxIcon } from "@chakra-ui/react";

import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase/firebase";

import { contactWave } from "../../assets/layout/index";
import { Form } from "./Form";

const Contact = ({ endpoint }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const init = {
    name: "",
    email: "",
    message: "",
    services: {
      web: false,
      data: false,
      ai: false,
      // devOps: false,
      // cloud: false,
      cyber: false,
    },
  };
  const [form, setForm] = useState(init);

  console.log("form", form);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setForm((prevData) => ({
        ...prevData,
        services: {
          ...prevData.services,
          [name]: checked,
        },
      }));
    } else {
      setForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    e.target.focus();
  };

  const addPost = async (form) => {
    const collectionRef = collection(db, "emails");
    const { name, email, message } = form;

    const services = [];

    for (const [key, value] of Object.entries(form.services)) {
      if (value) services.push(key);
    }

    console.log(services);

    try {
      await addDoc(collectionRef, {
        name,
        email,
        message,
        services,
        timeStamp: serverTimestamp(),
      });
      setSuccess(true);
      setLoading(false);
      setForm(init);
    } catch (err) {
      console.log(err);
      setError(err.message);
      setLoading(false);
      setForm(init);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    addPost(form);
  };

  return (
    <Box
      bgImage={contactWave}
      backgroundSize='cover'
      backgroundPosition='top'
      display={"flex"}
      alignItems={"center"}
      h='650px'
    >
      <Box as='section' w='100%' maxW='500px' mx={[4, "auto"]} id='contact'>
        <Heading textAlign='center' mb={8}>
          Level Up!
        </Heading>
        {success || error ? (
          <Alert status={success ? "success" : "error"}>
            {success ? CheckboxIcon : <AlertIcon />}
            {success
              ? "Thank you for contacting! We will get back to you in 24 hours!"
              : error}
          </Alert>
        ) : (
          <Form
            endpoint={endpoint}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            form={form}
            loading={loading}
          />
        )}
      </Box>
    </Box>
  );
};

export default Contact;
