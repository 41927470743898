import React, { useEffect } from "react";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import Swiper from "swiper";
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import Webeet from "./pages/webeet";
import customTheme from "./customTheme";
import Masterschool from "./pages/masterschool/masterschool";
import Apply from "./pages/apply/Apply";
import NotFound from "./pages/not_found";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  Swiper.use([Navigation, Autoplay, Pagination, EffectCoverflow]);

  const ScrollToHashElement = () => {
    const location = useLocation();

    useEffect(() => {
      const handleScroll = () => {
        if (location.hash) {
          const targetId = location.hash.substring(1);
          const element = document.getElementById(targetId);

          if (element) {
            const yOffset = -80;
            const yPosition =
              element.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;

            window.scrollTo({ top: yPosition, behavior: "smooth" });
          }
        }
      };
      setTimeout(handleScroll, 0);

      window.addEventListener("hashchange", handleScroll);

      return () => {
        window.removeEventListener("hashchange", handleScroll);
      };
    }, [location]);

    return null;
  };

  return (
    <ChakraProvider theme={customTheme}>
      <Router>
        <ScrollToTop />
        <ScrollToHashElement />
        <Routes>
          <Route path='/' element={<Webeet />} />
          <Route path='/masterschool/cohort/:id' element={<Masterschool />} />
          <Route
            path='/apply/cohort/:cohort_id/job/:job_id'
            element={<Apply />}
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
