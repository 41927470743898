import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/Hero";
import How from "../components/How/How";
import Companies from "../components/Companies/Companies";
import Services from "../components/Services/Services";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";

const Webeet = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <How />
      <Companies />
      <Services />
      <Contact />
      <Footer />
    </>
  );
};

export default Webeet;
