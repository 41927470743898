import { Flex, Image, Button } from "@chakra-ui/react";
import logo from "../../assets/logos/logo.png";

export const Navbar = ({ noContact }) => {
  return (
    <Flex
      as='nav'
      padding='1rem'
      position='sticky'
      top={4}
      left={0}
      zIndex={999}
      width='1200px'
      maxW='100%'
      mx='auto'
      alignItems='center'
      justifyContent='space-between'
      boxShadow='md'
      backdropFilter='blur(7.5px)'
      borderRadius='15px'
      px={8}
    >
      <Image
        src={logo}
        alt='logo'
        boxSize={["35px", "50px"]}
        objectFit='contain'
        onClick={(e) => {
          e.preventDefault(); // Prevent the default anchor behavior
          window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll smoothly to the top
        }}
      />
      {!noContact && (
        <Button
          bgColor='#B9B0E8'
          color='white'
          onClick={(e) => {
            e.preventDefault(); // Prevent the default anchor jump behavior
            const element = document.getElementById("contact");
            if (element) {
              const yOffset = -80; // Adjust this based on your navbar height
              const yPosition =
                element.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;
              window.scrollTo({ top: yPosition, behavior: "smooth" });
            }
          }}
        >
          Contact
        </Button>
      )}
    </Flex>
  );
};
export default Navbar;
