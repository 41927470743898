import {
  Heading,
  Box,
  Card,
  CardBody,
  Text,
  Image,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";

import idea from "../../assets/illustrations/idea.png";

import { services } from "../content";

const Services = () => {
  return (
    <Box
      as='section'
      maxW='1140px'
      mx={[2, 4, 8, "auto"]}
      id='services'
      my={12}
    >
      <Heading textAlign='center' my={8}>
        Our Services
      </Heading>
      <Card variant='filled' bg='#D9EDF8' mb={10}>
        <CardBody
          display='flex'
          alignItems='center'
          p={10}
          flexDir={["column", "row"]}
          gap={[5, 0]}
        >
          <Box
            maxW={["100%", "50%"]}
            display={"flex"}
            flexDir={["column"]}
            gap={[2, 4]}
          >
            <Heading size='md'>FROM IDEA TO LAUNCH</Heading>
            <Text fontSize='lg'>
              We are here to help you materialize your vision. We will help you
              define the goals, timelines and features, set a clear scope and
              take you all the way to launch.
            </Text>
          </Box>
          <Image src={idea} boxSize='150px' mx='auto' />
        </CardBody>
      </Card>
      <SimpleGrid gap={8} columns={[1, 1, 2, 2, 2]}>
        {services.map((service, i) => (
          <Service key={i} service={service} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

const Service = ({ service }) => {
  return (
    <Card h='full' variant='outline'>
      <CardBody display={"flex"} flexDir={"column"} gap={[2, 4]}>
        <Flex align={"center"} justify={"space-between"} mb={[10, 0]}>
          <Heading size={["md", "md", "lg", "lg", "lg"]} color='#B9B0E8'>
            {service.title}
          </Heading>
          <Image src={service.image} boxSize='50px' mr='20px' />
        </Flex>
        {service.description.map((d, index) => (
          <Text key={index}>{d}</Text>
        ))}
      </CardBody>
    </Card>
  );
};

export default Services;
