import { Flex, FormLabel, Select } from "@chakra-ui/react";

const InputSection = ({ setDomain }) => {
  return (
    <Flex
      mt={10}
      width='500px'
      maxWidth='100%'
      flexDirection='column'
      justify={"center"}
      mx={"auto"}
      px={[5, 5, 10, 10, 10]}
    >
      <FormLabel>Filter by Program</FormLabel>
      <Select
        placeholder='Select an option'
        onChange={(e) => setDomain(e.target.value)}
      >
        <option value='data'>Data Analyst</option>
        <option value='software'>Software Engineer</option>
        <option value='security'>Security Analyst</option>
      </Select>
    </Flex>
  );
};

export default InputSection;
