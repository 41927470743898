import {
  Box,
  Link,
  Image,
  Heading,
  Tooltip,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import {
  h25,
  ceevee,
  magicYard,
  merryPopin,
  menow,
  qelos,
  srisa,
  formelio,
  mindguard,
} from "../../assets/companies";

import { Swiper, SwiperSlide } from "swiper/react";

const Companies = ({ size = "150px" }) => {
  const slidesPerView = useBreakpointValue({
    base: 1,
    sm: 1,
    md: 3,
    lg: 4,
    xl: 5,
  });

  const companies = [
    {
      image: mindguard,
      alt: "Mind Guard",
      url: "https://getmindguard.com/",
      isWhite: false,
    },
    {
      image: qelos,
      alt: "Qelos",
      url: "https://qelos.io/",
      isWhite: false,
    },
    {
      image: h25,
      alt: "Hour 25",
      url: "https://www.hour25.ai/",
      isWhite: false,
    },
    {
      image: ceevee,
      alt: "CeeVee",
      url: "https://www.ceevee.ai/",
      isWhite: false,
    },
    {
      image: magicYard,
      alt: "Magic Yard",
      url: "https://magicyard.co/",
      isWhite: false,
    },
    {
      image: menow,
      alt: "Me Now",
      url: "https://www.menow.ai/",
      isWhite: true,
    },
    {
      image: merryPopin,
      alt: "Merry Popin",
      url: "https://merrypopin.com/",
      isWhite: false,
    },
    {
      image: srisa,
      alt: "Srisa",
      url: "https://srisa.org/",
      isWhite: true,
    },
    {
      image: formelio,
      alt: "Formelio",
      url: "https://www.formelio.com/",
      isWhite: false,
    },
  ];

  return (
    <Box as='section' my={12} id='work'>
      <Heading textAlign={"center"} mb={20}>
        Amazing Startups Working With Us
      </Heading>
      <Swiper
        slidesPerView={slidesPerView}
        autoplay={{ delay: 2000, pauseOnMouseEnter: true }}
        pagination={{ clickable: true, dynamicBullets: true }}
        loop
        centeredSlides
      >
        {companies.map((company) => (
          <SwiperSlide key={company.alt}>
            <Flex justify='center'>
              <Link href={company.url} isExternal key={company.alt}>
                <Tooltip label={company.alt} placement='bottom'>
                  <Image
                    boxSize={size}
                    objectFit='contain'
                    src={company.image}
                    alt={company.alt}
                    p={company.isWhite ? [1, 3] : ""}
                    bg={company.isWhite ? "#333" : ""}
                    borderRadius={company.isWhite ? "xl" : ""}
                    filter={["none", "grayscale(100%)"]}
                    _hover={{ filter: "none" }}
                    transition='filter 0.3s ease'
                  />
                </Tooltip>
              </Link>
            </Flex>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Companies;
