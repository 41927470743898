// src/theme/index.js

import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: 'Kanit, sans-serif', // Set the custom font for the body text
    heading: 'Kanit, sans-serif', // Set the custom font for headings
  },
  // ... Other theme configurations ...
});

export default theme;
